import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import UserService from "../services/user.service";
import { loadScript } from "../utility/loadScriptUtil";
import { PulseLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";

import {
  getCategories,
  getCategoryGroups,
  getProductSearchData,
  getShoppingCartData,
  removeCartItemPOST,
} from "../services/apiServices";

import swal from "sweetalert2";
import $ from "jquery";
import "../resources/css/search.css";
import cart from "../common/cart";
import useCartStore from "../common/globalStore";
import AuthService from "../services/auth.service";

export default function Header() {
  let navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));
  const [categoryState, setCategoryState] = useState([]);
  const [categoryGrpState, setCategoryGrpState] = useState([]);
  // const [shoppingCartState, setShoppingCartState] = useState([]);
  //const [cartloading, setCartLoading] = useState(false);
  const [skuFilter, setSkuFilter] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const shoppingCartState = useCartStore((state) => state.cartData);
  const getCartData = useCartStore((state) => state.updateCart);
  const cartloading = useCartStore((state) => state.cartloading);

  //get product categories
  const categoryData = async () => {
    getCategories()
      .then((res) => {
        setCategoryState(res.data);
        loadScript();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get product category groups
  const categoryGrpData = async () => {
    getCategoryGroups()
      .then((res) => {
        setCategoryGrpState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get shopping cart data
  // const getCartData = async () => {
  //   getShoppingCartData()
  //     .then((res) => {
  //       const cartItem = res.data.products;
  //       if (cartItem != null) {
  //         if (cartItem.length > 0) setCartLoading(true);
  //       } else {
  //         setCartLoading(true);
  //       }
  //       setShoppingCartState(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setCartLoading(true);
  //     });
  // };

  //remove cart item
  const removeCartItem = async (cartProductId) => {
    removeCartItemPOST(cartProductId)
      .then((res) => {
        // swal(
        //   "Success",
        //   "Item has been deleted to your Shopping Cart!",
        //   "success"
        // );
        swal.fire({
          position: "top",
          icon: "success",
          title: "Success",
          text: "Items has been deleted from Shopping Cart!",
          showConfirmButton: false,
          timer: 1500,
        });
        getCartData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //search product
  const searchProduct = async (sku) => {
    let selectedCat = $(".selectOption").val();
    if (sku.length > 2) {
      getProductSearchData(selectedCat, sku)
        .then((res) => {
          setFilterData(res.data);
          console.log(res.data.length);
          if (res.data.length > 0) {
            setSkuFilter(true);
            $("#tooltipSearchSuggestions, #abortTooltipSearch").fadeIn(222);
          } else {
            setSkuFilter(false);
            $("#tooltipSearchSuggestions,#abortTooltipSearch").fadeOut(222);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setFilterData([]);
      setSkuFilter(false);
      $("#tooltipSearchSuggestions,#abortTooltipSearch").fadeOut(222);
    }
  };

  //clear filterdata
  function clearSearch() {
    $("#tooltipSearch").val("");
    setFilterData([]);
    setSkuFilter(false);
    $("#tooltipSearchSuggestions,#abortTooltipSearch").fadeOut(222);
  }

  //add to cart function
  function fnAddToCart(productId, modelId, qty, price) {
    cart.addToCart(productId, modelId, qty, price);
    setTimeout(
      function () {
        //Start the timer
        getCartData();
      }.bind(this),
      1000
    );
  }

  useEffect(() => {
    (async () => {
      categoryData();
      categoryGrpData();
      getCartData();
    })();
  }, []);

  return (
    <React.Fragment>
      {/* header-start */}
      <header className="header d-blue-bg">
        <div className="header-top">
          <div className="container custom-conatiner">
            <div className="header-inner" style={{ padding: "10px" }}>
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-7">
                  <div className="header-inner-start">
                    <div className="support d-none d-sm-block">
                      <p>
                        Need Help?
                        <a
                          href="tel:+001123456789"
                          style={{ color: "#ffffff" }}
                        >
                          &nbsp; +44 20 8455 3841
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-5 d-none d-lg-block">
                  <div className="header-inner-end text-md-end">
                    <div className="ovic-menu-wrapper ovic-menu-wrapper-2">
                      <ul>
                        <li>
                          <a href="/about">About Us</a>
                        </li>
                        {/* <li>
                          <a href="contact.html">Order Tracking</a>
                        </li> */}
                        <li>
                          <a href="/contactUs">Contact Us</a>
                        </li>
                        <li>
                          <a href="/terms">Terms and Conditions</a>
                        </li>
                        <li>
                          <a
                            onClick={() => AuthService.logOut()}
                            style={{ cursor: "pointer", color: "white" }}
                          >
                            Sign out
                          </a>
                        </li>
                        {/* <li>
                          <a href="faq.html">FAQs</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-mid">
          <div className="container custom-conatiner">
            <div className="heade-mid-inner">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4">
                  <div className="header__info header__info-2">
                    <div className="logo logo-3">
                      <a href="/home" className="logo-image">
                        <img src="../assets/img/logo/logo.png" alt="logo" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-4 d-none d-lg-block">
                  <div className="header__search">
                    <form>
                      <div className="header__search-box">
                        <div
                          id="abortTooltipSearch"
                          onClick={() => clearSearch()}
                        >
                          &times;
                        </div>
                        <input
                          className="search_arrow_box"
                          type="text"
                          id="tooltipSearch"
                          placeholder="Search SKU...."
                          onKeyUp={(e) => searchProduct(e.target.value)}
                        />
                        {/* <button
                          className="button button-2 button-3"
                          type="submit"
                        >
                          <i className="far fa-search"></i>
                        </button> */}
                        <div id="tooltipSearchSuggestions">
                          {skuFilter == true ? (
                            <div className="col-md-12">
                              <div
                                className="table-content table-responsive"
                                style={{ margin: "10px" }}
                              >
                                <table
                                  className="table"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <tbody>
                                    {filterData.map((el) => {
                                      let pUrl = "/product/" + el.sku;
                                      return (
                                        <tr key={el.sku}>
                                          <td className="odi">
                                            <img
                                              src={el.defaultImage}
                                              style={{ width: "35px" }}
                                            />
                                          </td>
                                          <td className="proname">
                                            <a href={pUrl}>{el.productName}</a>
                                            {/* <Link to={"/product/" + el.sku} >
                                              {el.productName}
                                            </Link> */}
                                            {el.tempStop == true ? (
                                              <span className="status_font">
                                                Temporary not available
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td className="price">
                                            {el.sellingPrice > 0 ? (
                                              <a className="amount">
                                                {UserService.numberFormat(
                                                  el.specialPrice
                                                )}
                                              </a>
                                            ) : el.orginalPrice > 0 ? (
                                              <a className="amount">
                                                {UserService.numberFormat(
                                                  el.orginalPrice
                                                )}
                                              </a>
                                            ) : (
                                              "0.00"
                                            )}
                                          </td>
                                          {/* <td className="action">
                                            {el.modelId === 0 ? (
                                              <a
                                                onClick={() =>
                                                  fnAddToCart(
                                                    el.productId,
                                                    el.modelId,
                                                    1,
                                                    price
                                                  )
                                                }
                                                style={{ color: "#263c97" }}
                                              >
                                                <i className="fa fa-cart-plus"></i>
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                          </td> */}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="header__search-cat">
                        <select className="selectOption" id="headerSearch">
                          <option value={0}>All Categories</option>
                          {categoryState.map((cat) => {
                            return (
                              <option
                                key={cat.categoryId}
                                value={cat.categoryId}
                              >
                                {cat.categoryName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-8 col-sm-8">
                  <div className="header-action">
                    <div className="block-userlink">
                      <a className="icon-link icon-link-2" href="/my-account">
                        <i className="flaticon-user"></i>
                        <span className="text">
                          <span className="sub">{user.name} </span>
                          My Account
                        </span>
                      </a>
                    </div>
                    {/* <div className="block-wishlist action">
                      <a className="icon-link icon-link-2" href="#.html">
                        <i className="flaticon-heart"></i>
                        <span className="count count-2">0</span>
                        <span className="text">
                          <span className="sub">Favorite</span>
                          My Wishlist
                        </span>
                      </a>
                    </div> */}
                    {!cartloading ? (
                      <PulseLoader color="#ffffff" size={10} />
                    ) : (
                      <div className="block-cart action">
                        <a
                          className="icon-link icon-link-2"
                          href="/shoppingCart"
                        >
                          <i className="flaticon-shopping-bag"></i>
                          <span className="count count-2">
                            {shoppingCartState.noOfItems}
                          </span>
                          <span className="text">
                            <span className="sub">Cart:</span>
                            {UserService.numberFormat(
                              shoppingCartState.cartTotal
                            )}
                          </span>
                        </a>

                        <div className="cart">
                          <div className="cart__mini">
                            <ul>
                              <div className="ex2">
                                <li>
                                  <div className="cart__title">
                                    <h4>Cart</h4>
                                    <span>
                                      ({shoppingCartState.noOfItems} Item in
                                      Cart)
                                    </span>
                                  </div>
                                </li>
                                {shoppingCartState.products != null &&
                                  shoppingCartState.products.map((el) => {
                                    return el.model === null ? (
                                      <li key={el.cartProductId}>
                                        <div className="cart__item d-flex justify-content-between align-items-center">
                                          <div className="cart__inner d-flex">
                                            <div className="cart__thumb">
                                              <a>
                                                <img src={el.image} alt="" />
                                              </a>
                                            </div>
                                            <div className="cart__details">
                                              <h6>
                                                <Link
                                                  to={
                                                    "/product/" +
                                                    el.productDetails.sku
                                                  }
                                                >
                                                  {el.quantity}
                                                  {" X "}
                                                  {
                                                    el.productDetails
                                                      .productName
                                                  }
                                                </Link>
                                              </h6>
                                              <div className="cart__price">
                                                <span>
                                                  {UserService.numberFormat(
                                                    el.sellingPrice
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="cart__del">
                                            <a
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                removeCartItem(el.cartProductId)
                                              }
                                            >
                                              <i className="fal fa-times"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </li>
                                    ) : (
                                      <li key={el.cartProductId}>
                                        <div className="cart__item d-flex justify-content-between align-items-center">
                                          <div className="cart__inner d-flex">
                                            <div className="cart__thumb">
                                              <a>
                                                <img src={el.image} alt="" />
                                              </a>
                                            </div>
                                            <div className="cart__details">
                                              <h6>
                                                <Link to={"/product/" + el.sku}>
                                                  {el.quantity}
                                                  {" X "}
                                                  {el.model.name}
                                                </Link>
                                              </h6>
                                              <div className="cart__price">
                                                <span>
                                                  {UserService.numberFormat(
                                                    el.sellingPrice
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="cart__del">
                                            <a
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                removeCartItem(el.cartProductId)
                                              }
                                            >
                                              <i className="fal fa-times"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                              </div>
                              <li>
                                <div className="cart__sub d-flex justify-content-between align-items-center">
                                  <h6>Subtotal</h6>
                                  <span className="cart__sub-total">
                                    {UserService.numberFormat(
                                      shoppingCartState.cartTotal
                                    )}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <Link
                                  to="/shoppingCart"
                                  state={shoppingCartState.cartId}
                                  className="wc-cart mb-10"
                                >
                                  View cart
                                </Link>
                                {/* <a href="checkout.html" className="wc-checkout">
                                  Checkout
                                </a> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header__bottom">
          <div className="container custom-conatiner">
            <div className="row g-0 align-items-center">
              <div className="col-lg-3">
                <div className="cat__menu-wrapper side-border d-none d-lg-block">
                  <div className="cat-toggle">
                    <button
                      type="button"
                      className="cat-toggle-btn cat-toggle-btn-1"
                    >
                      <i className="fal fa-bars"></i> Shop by department
                    </button>
                    <div className="cat__menu-2 cat__menu">
                      <nav id="mobile-menu" style={{ display: "none" }}>
                        <ul>
                          {categoryGrpState.length > 0
                            ? categoryGrpState.map((catg) => {
                                return (
                                  <li key={catg.groupId}>
                                    <a>
                                      {catg.groupName}
                                      <i className="far fa-angle-down"></i>
                                    </a>

                                    <ul className="mega-menu">
                                      {catg.mainCategory.map((main) => {
                                        return (
                                          <li key={main.categoryId}>
                                            <a
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                navigate({
                                                  pathname: "/category-product",
                                                  search:
                                                    "?category=" +
                                                    main.categoryId +
                                                    "&brand=[0]",
                                                });
                                                window.location.reload(true);
                                              }}
                                            >
                                              {main.categoryName}
                                            </a>

                                            <ul className="mega-item">
                                              {main.levelone.map((lvlo) => {
                                                return (
                                                  <li key={lvlo.categoryId}>
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        navigate({
                                                          pathname:
                                                            "/category-product",
                                                          search:
                                                            "?category=" +
                                                            lvlo.categoryId +
                                                            "&brand=0",
                                                        });
                                                        window.location.reload(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      {lvlo.categoryName}
                                                    </a>
                                                  </li>
                                                );
                                              })}
                                            </ul>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </li>
                                );
                              })
                            : ""}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-3">
                <div className="header__bottom-left d-flex d-xl-block align-items-center">
                  <div className="side-menu d-lg-none mr-20">
                    <button
                      type="button"
                      className="side-menu-btn offcanvas-toggle-btn"
                    >
                      <i className="fas fa-bars"></i>
                    </button>
                  </div>
                  <div className="main-menu d-none d-lg-block">
                    <nav>
                      <ul>
                        <li>
                          <a href="/home">Home</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="header__bottom d-none d-lg-block">
          <div className="container">
            <div className="box-items-inner pt-10 pb-10">
              <div className="main-menu d-none d-lg-block">
                <ul>
                  {categoryGrpState.length > 0
                    ? categoryGrpState.map((catg) => {
                        return (
                          <li key={catg.groupId}>
                            <div className="box-item">
                              <a href="shop.html">
                                <img
                                  src={catg.image}
                                  style={{ width: "32px" }}
                                  alt=""
                                />
                                &nbsp;
                                {catg.groupName}
                              </a>
                            </div>
                            <ul className="megamenu-1">
                              <li>
                                <a href="#">{catg.groupName}</a>
                                {catg.mainCategory.map((main) => {
                                  return (
                                    <ul
                                      key={main.categoryId}
                                      className="mega-item"
                                    >
                                      <li>
                                        <a href="index.html">
                                          {main.categoryName}
                                        </a>
                                      </li>
                                    </ul>
                                  );
                                })}
                              </li>
                            </ul>
                          </li>
                        );
                      })
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div> */}
      </header>

      {/* header-end */}
    </React.Fragment>
  );
}
